import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Flex, Stack } from "@chakra-ui/react";

const tagsLinkStyles = {
  color: "#FFF5F5",
  textDecoration: "none",
};
const categoryLinkStyles = {
  textDecoration: "none",
  color: "black",
};

const PostTop = ({ data }) => {
  const { category, tags, title, hero_image_alt, date } = data.mdx.frontmatter;
  const image = getImage(data.mdx.frontmatter.hero_image);

  return (
    <>
      <Box
        borderBottom={"solid"}
        pb={"15px"}
        borderColor={"gray.400"}
        align={"center"}
      >
        <Box alignContent={"center"}>
          <Box fontWeight={"700"} fontSize={"lg"}>
            {title}
          </Box>
          <Box maxW="lg">
            <GatsbyImage image={image} alt={hero_image_alt} />
          </Box>
          <Box>{date}</Box>
          <Stack direction={"column"}>
            <Box>
              カテゴリー：
              <Link
                to={"/blog/categories/" + category}
                style={categoryLinkStyles}
              >
                {category}
              </Link>
            </Box>
            <Box>
              <Stack direction={"row"}>
                <Flex m={"auto"}>
                  {tags.map((tag) => (
                    <Box
                      borderRadius={3}
                      p={1}
                      ml={2}
                      backgroundColor={"gray.600"}
                    >
                      <Link to={"/blog/tags/" + tag} style={tagsLinkStyles}>
                        #{tag}
                      </Link>
                    </Box>
                  ))}
                </Flex>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default PostTop;
