import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PostLayout from "../../components/postLayout";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "../../components/CodeBlock";

const components = {
  pre: CodeBlock,
};

const BlogPost = ({ data }) => {
  return (
    <PostLayout data={data}>
      <MDXProvider components={components}>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
    </PostLayout>
  );
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY.MM.DD")
        category
        tags
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default BlogPost;
