import * as React from "react";
import Seo from "./seo";
import { Box } from "@chakra-ui/react";
import Header from "./header";
import PostTop from "./PostTop";
import { Link } from "gatsby";

const PostLayout = ({ children, data }) => {
  const { title } = data.mdx.frontmatter;
  return (
    <>
      <Seo title={title} />
      <Box m={"10px"} mx="auto" maxW="lg">
        <Header />
        <PostTop data={data} />
        <Box maxW="xl" mx="auto">
          <main>{children}</main>
        </Box>

        <Box mt={5}>
          <Link to="/">戻る</Link>
        </Box>
      </Box>
    </>
  );
};
export default PostLayout;
